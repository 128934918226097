.second-page-general {
  @extend .full-width;
  padding-top: 75px;
  padding-bottom: 75px;
  .grid-list-container {
    padding-top: 64px;
    padding-bottom: 64px;
  }
}

.speakers-view {
  @extend .second-page-general;
  .top-header-bar {
    height: 120px;
    transform: translateY(-92px);
    @include md-rd-xs() {
      height: 200px;
    }
    div {
      height: 48px;
      box-sizing: border-box;
      border-radius: 100px;
      cursor: pointer;
    }
    h3 {
      @extend .color-white;
      margin: 0 0 -4px 0;
      font-size: 14px;
      letter-spacing: 0.8px;
      border-bottom: 4px solid transparent;
      &.current {
        border-bottom-color: $color-white;
      }
    }
    .longest-bar,
    .second-bar {
      display: flex;
      align-items: center;
      padding-left: 72px;
    }
    .longest-bar {
      position: relative;
      width: 336px;
      background-image: linear-gradient(42deg, #67eefb, #1c8ef7);
    }
    .second-bar {
      position: absolute;
      right: 0;
      z-index: 1;
      width: 177px;
      background-image: linear-gradient(25deg, #67eefb, #1c8ef7);
    }
  }
  .speakers-body {
    margin-top: 0;
    padding-top: 0;
    //padding-bottom: 128px;
  }
}

.teams-view {
  @extend .second-page-general;
}

.partners-view {
  @extend .second-page-general;
}

.startup-expo-view {
  @extend .second-page-general;
}