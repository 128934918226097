.apply-confirmation {
  padding-top: 75px;
  @include md-rd-xs {
    padding-top: 50px;
  }
  .confirmation-header {
    max-height: 320px;
    padding: 100px 0 0 100px;
    background-color: $color-white;
    @include md-rd-xs {
      padding: 48px 16px 40px;
    }
    img.title {
      @include size(auto, 48px);
      @include md-rd-xs {
        @include size(auto, 36px);
      }
    }
    .line {
      margin: 40px 0;
    }
    .leading-words {
      margin-top: 0;
      margin-bottom: 40px;
      max-width: 600px;
      font-size: 16px;
      color: $color-secondary;
      @include md-rd-xs {
        text-align: center;
        width: 260px;
        margin-bottom: 0;
      }
    }
  }
  .confirmation-body {
    padding: 46px 60px 120px;
    @include md-rd-xs {
      padding: 16px;
    }
    @include md-rd-sm {
      padding: 36px 32px 48px;
    }
    .subtitle {
      height: auto;
      max-height: 24px;
      @include md-rd-xs {
        height: 15px;
      }
    }
    img {
      max-width: 100%;
      margin-top: 4px;
      margin-bottom: 12px;
    }
    article {
      width: 100%;
      max-width: 960px;
      padding: 50px 100px;
      @include md-rd-xs {
        padding: 30px 10px;
      }
      @include md-rd-sm {
        padding: 48px 60px 48px;
      }
      background-color: $color-white;
      .booth-info {
        margin-bottom: 30px;
      }
      ul {
        @include md-rd-xs {
          padding-left: 30px;
        }
        li {
          list-style: circle;
          padding-bottom: 28px;
          img {
            margin: 0 0 -3px 0;
          }
        }
      }
      ol {
        padding-left: 20px;
        li {
          list-style: decimal;
          padding-bottom: 26px;
        }
      }
    }
  }
}