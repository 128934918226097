.image-slider {
  position: relative;
  max-width: 960px;
  width: 100%;
  .hint {
    font-size: 16px;
    margin-top: 40px;
    color: $color-hint;
    max-width: 640px;
    height: 150px;
  }
  .image-size {
    @include size(100%, 480px);
  }
  .image-slider-container {
    position: relative;
    max-width: 960px;
    width: 100%;
    .images-wrapper {
      position: relative;
      top: 0;
      left: 0;
      .slider-image {
        position: absolute;
        @extend .background-style;
      }
    }

    .image-selector-big {
      position: absolute;
      bottom: 0;
      left: 0;
      div {
        background: white;
        @include size(95px, 95px);
        img {
          margin: 20px 15px;
        }
      }
    }
    .image-selector-small {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      .left-arrow {
        position: absolute;
        left: 0;
        top: 55px;
      }
      .right-arrow {
        position: absolute;
        right: 0;
        top: 55px;
      }
    }
  }
}