.speaker-title {
  position: relative;
  height: 120px;
  @include md-rd-xs() {
    height: 200px;
  }
  > div {
    position: absolute;
    right: 0;
    height: 120px;
    box-sizing: content-box;
  }
  .longest-bar,
  .second-bar {
    display: flex;
    align-items: center;
    padding-left: 72px;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    @include md-rd-xs() {
      box-sizing: border-box;
      height: 80px;
      top: 120px;
      padding-left: 50px;
    }
    > h3 {
      @extend .color-white;
      cursor: pointer;
      margin: 0 0 -4px 0;
      font-size: 24px;
      letter-spacing: 1.3px;
      border-bottom: 4px solid transparent;
      &.current {
        border-bottom-color: $color-white;
      }
    }
  }
  .longest-bar {
    width: 65%;
    max-width: 1200px;
    background-image: linear-gradient(25deg, #67eefb, #1c8ef7);
    @include md-rd-sm() {
      max-width: calc(100% - 40px);
      min-width: 640px;
    }
    @include md-rd-xs() {
      border-radius: 0;
      width: 100%;
    }
  }
  .second-bar {
    z-index: 1;
    width: 40%;
    max-width: 800px;
    background-image: linear-gradient(25deg, #67eefb, #1c8ef7);
    @include md-rd-sm() {
      padding-left: 50px;
      max-width: calc(100% - 160px);
      min-width: 450px;
    }
    @include md-rd-xs() {
      width: 50%;
    }
  }
  .section-title {
    z-index: 2;
    top: -40px;
  }
}