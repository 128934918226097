.speaker-card {
  position: relative;
  padding-left: 55px;
  padding-top: 25px;
  width: 300px;
  height: 205px;
  box-sizing: border-box;
  @include md-rd-sm {
    width: 260px;
  }
  .speaker-avatar {
    position: absolute;
    left: 0;
    top: 0;
    @include size(115px, 115px);
    box-sizing: border-box;
    box-shadow: 10px 10px 15px -6px rgba(0, 0, 0, 0.34);
    @include md-rd-sm {
      @include size(75px, 75px);
    }
    img {
      @include size(115px, 115px);
      @include md-rd-sm {
        @include size(75px, 75px);
      }
    }
  }
  .speaker-detail {
    padding: 37px 19px 34px 76px;
    width: 245px;
    height: 180px;
    box-sizing: border-box;
    background-color: white;
    @include md-rd-sm {
      width: 205px;
      padding-left: 36px;
    }
    box-shadow: -5px 10px 28px 0 rgba(0, 0, 0, 0.12);
    .speaker-name {
      font-size: 16px;
      line-height: 1.13;
      color: $color-primary;
    }

    .speaker-description {
      font-size: 12px;
    }
  }
}


