.second-page-header {
  background: url('../images/second-page-header.png') no-repeat bottom center;
  height: 320px;
  background-size: cover;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    position: relative;
    color: $color-white;
    font-size: 48px;
    font-weight: 500;
    letter-spacing: 12px;
    &:before {
      position: absolute;
      width: 8px;
      height: 8px;
      background-image: linear-gradient(314deg, #ffd687, #ff6f6f);
      border-radius: 100px;
      content: '';
      left: -32px;
      top: 50%;
    }
    &:after {
      position: absolute;
      width: 8px;
      height: 8px;
      background-image: linear-gradient(314deg, #ffd687, #ff6f6f);
      border-radius: 100px;
      content: '';
      right: -32px;
      top: 50%;
    }
  }
}

.second-page-ticket-and-sponsor {
  width: 100%;
  @media (max-width: 700px) {
    flex-wrap: wrap;
  }
  .left-part-button {
    cursor: pointer;
    @media (max-width: 700px) {
      min-width: 100%;
    }
  }
  .more-partner {
    width: 360px;
    height: 64px;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    background-image: linear-gradient(36deg, #67eefb, #1c8ef7);
    cursor: pointer;
    @include md-rd-xs {
      width: 300px;
    }
    h3 {
      @extend .color-white;
      margin: 0;
      font-size: 24px;
      font-weight: 600;
    }
    @media (max-width: 700px) {
      margin-bottom: 50px;
    }
  }
  .sponsor-us {
    margin: 0 5% 0 20px;
    font-size: 20px;
    max-width: 700px;
    span {
      letter-spacing: 1.1px;
      line-height: 30px;
    }
    @media (max-width: 700px) {
      text-align: center;
      margin-right: 20px;
    }
  }
}