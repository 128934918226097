// 浏览器弹窗提示
.lyp-tips-compatibility {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  padding: 20px 48px 20px 10px;
  box-sizing: border-box;
  z-index: 100;
  a {
    color: $color-blue;
  }
  h1 {
    line-height: 1.5;
    font-size: 16px;
    color: $color-white;
    font-weight: 400;
  }
  .md-icon-button {
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    md-icon {
      svg {
        fill: $color-white;
      }
    }
  }
  @include md-rd-sm {
    padding: 10px 48px 10px 10px;
    h1 {
      text-align: left;
    }
  }
  @include md-rd-xs {
    padding: 10px 48px 10px 10px;
    h1 {
      font-size: 14px;
      text-align: left;
    }
  }
}