// 共有部分
.ticket-button {
  display: block;
  width: 120px;
  height: 35px;
  border-radius: 100px;
  background-image: linear-gradient(309deg, #67eefb, #1c8ef7);
  box-shadow: 0 2px 4px 0 rgba(2, 21, 40, 0.12);
  margin-right: 24px;
  line-height: 35px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: $color-white;
  text-decoration: none;
}

.switch-lang {
  @include size(48px, 16px);
  background-image: url('../images/icon-switch-lang.png');
  background-size: cover;
}

// 头部样式
.header {
  position: fixed;
  z-index: 100;
  top: 0;
  background-color: $color-white;
  box-shadow: 0 2px 4px 0 rgba(2, 21, 40, 0.12);
  @include size(100%, 75px);
  &.no-shadow {
    box-shadow: none;
  }
  .logos {
    .liaoyuan,
    .summit {
      background: {
        size: cover;
        repeat: no-repeat;
        position: 0 center;
      }
    }
  }
  .navs {
    height: 100%;
    a {
      height: 100%;
      line-height: 75px;
      color: $color-secondary;
      font-size: 14px;
      text-decoration: none;
      padding: 0 8px;
      &:last-child {
        margin-right: 0;
      }
      &.active {
        font-weight: 600;
        color: $color-blue;
      }
    }
  }
  .menus {
    margin-right: 24px;
  }
  .summit {
    background-image: url('../images/logo-summit.png');
    @include size(52px, 32px);
    margin-left: 40px;
    @include md-rd-xs {
      margin-left: 24px;
    }
  }
  .liaoyuan {
    margin-left: 16px;
    margin-right: 24px;
    background-image: url('../images/zh/slogan-liaoyuan.png');
    @include size(32px, 32px);
  }
  .menu {
    height: 40px;
    width: 48px;
    &.close {
      position: relative;
      &:before,
      &:after {
        display: block;
        content: '';
        width: 24px;
        height: 4px;
        background-image: linear-gradient(316deg, #67eefb, #1c8ef7);
        transform-origin: center;
        position: absolute;
        top: 20px;
        right: 12px;
      }
      &:before {
        transform: rotate(-45deg);
      }
      &:after {
        transform: rotate(45deg);
      }
    }
    &.hamburger {
      display: flex;
      justify-content: center;
      align-items: center;
      .hamburger-item {
        width: 24px;
        height: 4px;
        background-image: linear-gradient(316deg, #67eefb, #1c8ef7);
        &:before,
        &:after {
          content: '';
          display: block;
          width: 24px;
          height: 4px;
          background-image: linear-gradient(316deg, #67eefb, #1c8ef7);
        }
        &:before {
          transform: translateY(-8px);
        }
        &:after {
          transform: translateY(4px);
        }
      }
    }
  }
}

// 侧边栏
.sidebar {
  position: fixed;
  z-index: 99;
  top: -100vh;
  left: 0;
  right: 0;
  padding-top: 75px;
  box-sizing: border-box;
  @include size(100vw, 100vh);
  @include max-size(1920px, 100vh);
  background-color: $color-white;
  transition: top .5s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &.show {
    top: 0;
  }
  .sidebar-nav {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    > a {
      width: 100%;
      padding: 12px;
      text-align: center;
      font-size: 14px;
      line-height: 1.43;
      letter-spacing: 0.8px;
      color: $color-secondary;
      text-decoration: none;
      display: block;
      &.active {
        font-weight: 600;
        color: $color-blue;
      }
    }
  }
  .actions {
    margin-bottom: 48px;
    padding: 12px 0;
    .ticket-button {
      margin: 0 0 12px 0;
    }
    .switch-lang-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 12px;
    }
  }
}

// 遮罩
.overlay {
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  @include size(100vw, 100vh);
  background-color: rgba(0, 0, 0, 0.5);
}

// 微信弹框
.wx-qr {
  @include size(200px, 200px);
  max-width: initial;
  background-color: white;
  border-radius: 4px;
  position: absolute;
  bottom: 180%;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  img {
    width: 100%;
  }
}

.footer {
  background-color: rgba(8, 33, 59, 0.87);
  color: white;
  padding: 100px 50px 48px;
  &.should-bump {
    transform: translateY(-60px);
    @include md-rd-xs {
      transform: translateY(0);
    }
  }
  @include md-rd-xs {
    padding-left: 24px;
    padding-right: 24px;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  .logo {
    display: block;
    width: 180px;
  }
  .sub-menus {
    margin: 40px 0 28px 0;
    font-size: 12px;
    ul {
      display: flex;
      justify-content: center;
      padding: 0;
      li {
        text-align: center;
        margin: 12px;
        @include md-rd-xs() {
          margin: 8px;
        }
      }
    }
  }
  .copyright {
    font-size: 12px;
  }
}