.section-indicator {
  width: 307px;
  height: 161px;
  background-image: url('../images/section-header-bg.png');
  position: relative;
  .number {
    z-index: 3;
    position: absolute;
    margin: 0;
    top: -62px;
    left: 55px;
    background-image: linear-gradient(338deg, #67eefb, #1c8ef7);
    font-size: 120px;
    letter-spacing: 6.7px;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .title {
    z-index: 2;
    font-size: 32px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 8px;
    color: $color-white;
    margin: 0;
    position: absolute;
    bottom: 34px;
    left: 60px;
  }
}

.round-speaker-profile {
  box-sizing: content-box;
  @extend .color-primary;
  .avatar-icon-wrapper {
    height: 120px;
    width: 120px;
    position: relative;
    .blue,
    .red,
    img {
      bottom: 0;
      position: absolute;
      width: 120px;
      height: 120px;
      border-radius: 100px;
    }
    .blue {
      width: 116px;
      height: 120px;
      border-radius: 100px;
      background-image: linear-gradient(340deg, #67eefb, #1c8ef7);
      left: 6px;
      bottom: 6px;
    }
    .red {
      width: 116px;
      height: 120px;
      border-radius: 100px;
      background-image: linear-gradient(264deg, #ffd687, #ff6f6f);
      left: -2px;
      bottom: 4px;
    }
  }
  .name {
    margin: 8px 0;
    font-size: 16px;
    line-height: 1.25;
    letter-spacing: 0.9px;
    font-weight: 400;
  }
  .description {
    margin: 0;
    font-size: 12px;
    font-weight: 300;
    text-align: center;
  }
}

.square-team-partner {
  @extend .color-primary;
  box-sizing: content-box;
  > h5 {
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    margin: 24px 0 8px 0;
  }
  > p {
    margin: 0;
    text-align: center;
    font-size: 12px;
    font-weight: 300;
  }
  .partner-logo-wrapper {
    position: relative;
    width: 80px;
    height: 80px;
    .blue,
    .red,
    > div {
      bottom: 0;
      position: absolute;
      @include size(80px, 80px);
      border-radius: 8px;
    }
    .blue {
      z-index: 2;
      transform: rotateZ(4deg);
      background-image: linear-gradient(340deg, #67eefb, #1c8ef7);
    }
    .red {
      z-index: 1;
      transform: rotateZ(8deg);
      background-image: linear-gradient(264deg, #ffd687, #ff6f6f);
    }
    > div {
      z-index: 3;
      background: white no-repeat center;
      background-size: contain;
    }
  }

}

