// normalize
body {
  font-family: PingFang SC, RobotoDraft, Roboto, "Helvetica Neue", Helvetica, Arial, STHeiti, "华文黑体", "Microsoft YaHei", "微软雅黑", sans-serif;
  background-color: white;
  -webkit-font-smoothing: antialiased;
}

button,
select,
html,
textarea,
input {
  font-family: PingFang SC, RobotoDraft, Roboto, "Helvetica Neue", Helvetica, Arial, STHeiti, "华文黑体", "Microsoft YaHei", "微软雅黑", sans-serif;
}

:focus {
  outline: none;
}

input {
  -webkit-appearance: none;
}

ul,
ol {
  list-style: none;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.full-screen {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
}

.full-size {
  width: 100%;
  height: 100%;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.cursor-pointer {
  cursor: pointer;
}

.no-margin {
  margin: 0;
}

// 位置
.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.position-absolute-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.no-scroll {
  overflow-y: hidden;
}

// 大小
@mixin size($width: 100%, $height: 100%) {
  width: $width;
  height: $height;
}

@mixin max-size($max-width: 100vh, $max-height: 100vw) {
  max-width: $max-width;
  max-height: $max-height;
}

@mixin min-size($min-width, $min-height) {
  min-width: $min-width;
  min-height: $min-height;
}

// 相应式规则
$_ly-xs-width: 320px;
$_md-sm-width: 600px;
$_md-md-width: 960px;
$_md-lg-width: 1280px;

// x < 700
@mixin md-rd-summit-xs {
  @media (max-width: 699px) {
    @content;
  }
}

// x < 600
@mixin md-rd-xs {
  @media (max-width: #{$_md-sm-width - 1px}) {
    @content;
  }
}

// x >= 600
@mixin md-rd-gt-xs {
  @media (min-width: #{$_md-sm-width}) {
    @content;
  }
}

// 600 <= x < 960
@mixin md-rd-sm {
  @media (min-width: #{$_md-sm-width}) and (max-width: #{$_md-md-width - 1px}) {
    @content;
  }
}

// 600 <= x < 960
@mixin md-rd-lt-md {
  @media (max-width: #{$_md-md-width - 1px}) {
    @content;
  }
}

// x < 960
@mixin md-rd-lt-md {
  @media (max-width: #{$_md-md-width - 1px}) {
    @content;
  }
}

// x >= 960
@mixin md-rd-gt-sm {
  @media (min-width: #{$_md-md-width}) {
    @content;
  }
}

// 960 <= x < 1280
@mixin md-rd-md {
  @media (min-width: #{$_md-md-width}) and (max-width: #{$_md-lg-width - 1px}) {
    @content;
  }
}

// x >= 1280
@mixin md-rd-gt-md {
  @media (min-width: #{$_md-lg-width}) {
    @content;
  }
}

%background-style {
  background: {
    size: cover;
    repeat: no-repeat;
    position: center center;
  }
}

.no-shrink {
  flex-shrink: 0;
}

// 强制一行
.overflow-hidden {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
}

@mixin overflow-hidden($width: 100%) {
  width: $width;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
}

.limit-one-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

@mixin limit-lines($lines: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}

// 背景样式
.background-style {
  background: {
    size: cover;
    repeat: no-repeat;
    position: center center;
  }
}

// 颜色
$color-primary: rgba(0, 0, 0, .87);
$color-secondary: rgba(0, 0, 0, .54);
$color-hint: rgba(0, 0, 0, 0.34);
$color-black: black;
$color-white: #fdfdfd;
$color-blue: #0a88ff;
$color-red: #ea1c43;
$color-orange: #f44336;
$color-grey: #f7f7f7;
$color-background: #f3f4f7;
$ly-bg-tag: rgba(0, 0, 0, 0.04) !default;

.color-primary {
  color: $color-primary;
}

.color-blue {
  color: $color-blue;
}

.color-white {
  color: $color-white;
}

.color-secondary {
  color: $color-secondary;
}

.color-red {
  color: $color-red;
}

.color-orange {
  color: $color-orange;
}

.link-blue {
  color: $color-blue;
  text-decoration: none;
}

.icon-color-blue {
  svg {
    fill: $color-blue;
  }
}

[ng-click],
button {
  outline: none;
  cursor: pointer;
  &.no-cursor-pointer {
    cursor: inherit;
  }
}

// 渐变线
@mixin line($width, $height: 2px) {
  @include size($width, $height);
  background-image: linear-gradient(136deg, $color-blue, $color-orange);
}

// 背景渐变按钮
.button-style {
  border: none;
  padding: 0;
  margin: 0;
  @include size(215px, 64px);
  background: {
    size: 100% 100%;
    repeat: no-repeat;
    image: url('../images/button-background.png');
    color: transparent;
    position: 0 4px;
  }
  color: $color-white;
  font-size: 16px;
  letter-spacing: 3px;
  line-height: 0.9;
  text-align: center;
  &.button-background-alternative {
    background: {
      image: url('../images/button-background-alternative.png');
    }
  }
  @include md-rd-gt-xs {
    &:hover {
      background-image: url('../images/button-background-hover.png');
      color: $color-orange;
    }
  }

}

// 渐变的包围线
.line-wrapper {
  border: {
    style: solid;
    width: 1px;
  }
  border-image: {
    source: linear-gradient(163deg, $color-blue, #4178cf, $color-orange);
    slice: 1;
  }
}

.line-wrapper-ie {
  border: 1px solid $color-orange;
}

// 渐变的包围线动画 1px
.border-animate-wrapper {
  @include md-rd-gt-xs {
    overflow: hidden;
    .border-animate {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        top: -1px;
        right: -1px;
        @include size(calc(100% + 1px), 0);
        background-color: transparent;
        border-style: solid;
        border-width: 1px 1px 0 0;
        border-image-source: linear-gradient(163deg, $color-blue, #4178cf, $color-orange);
        border-image-slice: 1;
        transform: translateX(-100%);
      }
      &:after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: -1px;
        @include size(calc(100% + 1px), 0);
        background-color: transparent;
        border-style: solid;
        border-width: 0 0 1px 1px;
        border-image-source: linear-gradient(163deg, $color-blue, #4178cf, $color-orange);
        border-image-slice: 1;
        transform: translateX(100%);
      }
      &:hover {
        border-color: transparent;
        border-image-source: none;
        &:before {
          transition: transform .2s linear, height .2s linear .3s;
          transform: translateX(0);
          @include size(calc(100% + 1px), calc(100% + 1px))
        }
        &:after {
          transition: transform .2s linear .5s, height .2s linear .7s;
          transform: translateX(0);
          @include size(calc(100% + 1px), calc(100% + 1px))
        }

      }
    }
  }
}

a, a:hover, a:visited, a:active {
  text-decoration: none;
}

// 邮件链接
a.email {
  color: $color-blue;
  text-decoration: none;
}

// 动画
.animate-fade-in {
  &.ng-enter,
  &.ng-leave,
  &.ng-move {
    transition: all 1s ease;
  }
  &.ng-enter,
  &.ng-move,
  &.ng-leave.ng-leave-active {
    // 消失
    opacity: 0;
  }
  &.ng-leave,
  &.ng-move.ng-move-active,
  &.ng-enter.ng-enter-active {
    // 出现
    opacity: 1;
  }
}

//对话框
md-dialog {
  @extend .line-wrapper;
}

// 字体
.font-16 {
  font-size: 16px;
  font-weight: 400;
  vertical-align: middle;
  line-height: 24px;
}

@mixin max-size($max-width: 100vw, $max-height: 100vh) {
  max-width: $max-width;
  max-height: $max-height;
}

@mixin min-size($min-width, $min-height) {
  min-width: $min-width;
  min-height: $min-height;
}

@mixin button-size($width: 48px, $height: 48px) {
  width: $width;
  height: $height;
}

@mixin icon-size($diameter: 24px) {
  width: $diameter;
  height: $diameter;
  @include min-size($diameter, $diameter);
}

