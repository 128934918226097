.sections {
  padding-top: 75px;
  transition: padding-top 0.3s ease;
  background-color: $color-grey;
  overflow-x: hidden;
  .scroll-indicator {
    position: fixed;
    right: 24px;
    top: calc(50% + 50px);
    transform: translateY(-50%);
    z-index: 10;
    > a {
      display: block;
      text-decoration: none;
      margin-bottom: 24px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-image: linear-gradient(340deg, #67eefb, #1c8ef7);
      transition: all 0.6s ease;
      &:hover {
        transform: scale(1.2);
      }
      &:last-child {
        margin-bottom: 0;
      }
      &.active {
        transform-origin: center center;
        transform: translateX(8px);
        width: 24px;
        margin-left: -16px;
        border-radius: 100px;
        background-image: linear-gradient(310deg, #67eefb, #1c8ef7);
      }
    }
  }
  .screen-height {
    min-height: 400px;
    max-height: 1024px;
    height: calc(100vh - 75px);
    @include md-rd-xs {
      height: calc(100vh - 50px);
    }
  }
  > section {
    max-width: 1920px;
    margin: 0 auto;
  }
  .section-indicator-title {
    width: 307px;
    height: 120px;
    border-radius: 100px;
    background-image: linear-gradient(18deg, #67eefb, #1c8ef7);
  }
  .section-end-bar {
    margin-top: 64px;
    width: 360px;
    height: 64px;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    background-image: linear-gradient(36deg, #67eefb, #1c8ef7);
    cursor: pointer;
    @include md-rd-xs {
      width: 300px;
    }
    h3 {
      @extend .color-white;
      margin: 0;
      font-size: 24px;
      font-weight: 600;
    }
  }
  .grid-list-container {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  // 新的封面
  #first {
    background: {
      image: url('../images/background.png');
      repeat: no-repeat;
      size: cover;
      position: 0 -125px;
    }
    position: relative;
    padding-bottom: 60px;
    @include md-rd-xs() {
      background-size: contain;
      background-position-y: 0;
    }
    .review {
      position: absolute;
      right: 128px;
      top: 73px;
      > img {
        width: 107px;
        @include md-rd-xs() {
          width: 64px;
        }
      }
      @include md-rd-lt-md() {
        right: 24px;
        top: 48px;
      }
    }
    .title-wrapper {
      z-index: 20;
      margin-bottom: 75px;
      margin-left: 75px;
      @include md-rd-xs {
        margin-bottom: 20px;
        margin-left: 20px;
      }
    }
    .title-zh {
      img {
        height: 180px;
        width: auto;
        @include md-rd-sm {
          width: 300px;
          height: auto;
        }
        @include md-rd-xs {
          width: 200px;
          height: auto;
        }
      }
      .text-wrapper {
        @include md-rd-gt-sm() {
          margin-left: -12px;
        }
      }
      .title {
        color: $color-primary;
        font-size: 48px;
        font-weight: bold;
        @include md-rd-sm {
          font-size: 72px;
        }
        @include md-rd-gt-sm() {
          font-size: 102px;
          font-weight: bold;
          line-height: 1.18;
          letter-spacing: 7.3px;
        }
      }
      .sub-title {
        color: $color-primary;
        margin: 0;
        font-size: 22px;
        font-weight: 300;
        letter-spacing: 10.9px;
        @include md-rd-sm {
          font-size: 36px;
          letter-spacing: 16px;
          margin-top: 12px;
        }
        @include md-rd-gt-sm() {
          transform: translateX(-44px);
          font-size: 52px;
          line-height: 0.92;
          letter-spacing: 24px;
          margin-top: 12px;
        }
      }
    }
    .title-en {
      img {
        width: 600px;
        @include md-rd-sm {
          width: 400px;
        }
        @include md-rd-xs {
          width: 280px;
        }
      }
      .text-wrapper {
        div {
          font-size: 22px;
          font-weight: bold;
          color: $color-primary;
          @include md-rd-sm {
            font-size: 48px;
          }
          @include md-rd-gt-sm() {
            font-size: 72px;
            line-height: 1.67;
          }
        }
      }
    }
    .info-bar {
      width: calc(100% - 128px);
      background-image: linear-gradient(69deg, #67eefb, #1c8ef7);
      box-sizing: border-box;
      color: $color-white;
      z-index: 10;
    }
    .info-gt-xs {
      height: 64px;
      border-top-right-radius: 100px;
      border-bottom-right-radius: 100px;
      font-size: 24px;
      font-weight: 600;
      padding-left: 120px;
      max-width: 960px;
      @include md-rd-lt-md() {
        font-size: 16px;
        padding-left: 16px;
        width: calc(100% - 48px);
      }
      div {
        display: flex;
        align-items: center;
        padding: 0 40px 0 20px;
        position: relative;
        height: 64px;
        @include md-rd-lt-md() {
          padding-right: 20px;
        }
        &:not(.purchase):before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          border: {
            left: 5px solid transparent;
            right: 5px solid transparent;
            top: 7px solid #f5f5f5;
          }
        }
      }
      .purchase {
        cursor: pointer;
      }
    }
    .info-xs {
      width: 100%;
      padding: 12px 16px;
      > div {
        &:first-child {
          margin-bottom: 12px;
        }
      }
    }
    .info-purchase-xs {
      width: 100%;
      margin-top: 20px;
      > div {
        padding: 8px 32px;
        width: 65%;
        max-width: 200px;
        border-top-right-radius: 100px;
        border-bottom-right-radius: 100px;
        background-image: linear-gradient(28deg, #67eefb, #1c8ef7);
        color: $color-white;
      }
    }
  }
  #partner {
    padding-top: 75px;
    @include md-rd-xs {
      padding-top: 120px;
    }
    .introduction {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include md-rd-summit-xs() {
        flex-direction: column;
        justify-content: center;
      }
      .liaoyuan {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 160px;
        @include md-rd-summit-xs() {
          margin-top: 24px;
          order: 1;
          flex-direction: column;
        }
        @include md-rd-lt-md() {
          margin-left: 0;
        }
        h2 {
          margin: 0;
          font-size: 36px;
          font-weight: 400;
          letter-spacing: 16.6px;
          color: $color-primary;
        }
        h4 {
          margin: 18px 0 0 0;
          background-image: linear-gradient(296deg, #67eefb, #1c8ef7);
          font-size: 24px;
          font-weight: 500;
          letter-spacing: 6px;
          text-align: left;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .logo-wrapper {
          background-image: url('../images/主办方@2x.png');
          background-size: cover;
          width: 250px;
          height: 250px;
          img {
            display: block;
            width: 157px;
            margin-left: 53px;
            margin-top: 59px;
          }
        }
      }
      .section-title-wrapper {
        @include md-rd-summit-xs {
          width: 100%;
        }
      }
    }
    .liaoyuan-description {
      white-space: pre-wrap;
      max-width: 776px;
      color: $color-primary;
      font-size: 14px;
      line-height: 1.71;
      padding: 60px 0 60px 160px;
      @include md-rd-sm {
        padding-left: 24px;
      }
      @include md-rd-summit-xs() {
        padding: 24px 24px 80px;
      }
    }
    .more {
      margin-top: 64px;
      @media (max-width: 700px) {
        flex-wrap: wrap;
      }
      .more-button {
        .section-end-bar {
          margin-top: 0;
        }
        @media (max-width: 700px) {
          min-width: 100%;
        }
      }
      .more-partner {
        @media (max-width: 700px) {
          margin-bottom: 50px;
        }
      }
      .sponsor-us {
        margin: 0 5% 0 20px;
        font-size: 20px;
        max-width: 700px;
        span {
          letter-spacing: 1.1px;
          line-height: 30px;
        }
        @media (max-width: 700px) {
          text-align: center;
          margin-right: 20px;
        }
      }
    }
  }
  #speaker {
    padding-top: 160px;
  }

  #agenda,
  #review,
  #team,
  #media,
  #contact {
    padding-top: 120px;
  }

  #agenda {
    //圆形日期元素共有部分
    .divider-title {
      position: relative;
      color: $color-white;
      border-radius: 100px;
      background-image: linear-gradient(302deg, #67eefb, #1c8ef7);
      @include md-rd-xs() {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
    // 日期分割单元
    .date-divider {
      padding-left: 140px;
      margin-top: 36px;
      @include md-rd-sm() {
        padding-left: 32px;
        margin-top: 36px;
      }
      @include md-rd-xs() {
        padding-left: 0;
        margin-top: 24px;
      }

      .date-title {
        padding: 12px 48px 12px 24px;
        height: 64px;
        h4 {
          font-size: 32px;
          font-weight: 500;
          letter-spacing: 1.6px;
          margin: 0 8px 0 0;
        }
        span {
          font-size: 18px;
        }
      }
      .separator {
        height: 2px;
        border-top: 1px solid $color-blue;
      }
    }

    //特殊午餐分割单元
    .day-divider {
      margin-top: 32px;
      .day-title {
        padding: 8px 12px;
        height: 32px;
        font-size: 12px;
        @include md-rd-xs() {
          border-top-left-radius: 100px;
          border-bottom-left-radius: 100px;
        }
      }
      .dashed-separator {
        height: 2px;
        border-top: 1px dashed $color-blue;
      }
    }

    .sep-29th,
    .sep-30th,
    .oct-1st {
      padding-left: 200px;
      @include md-rd-sm() {
        padding-left: 88px;
      }
      @include md-rd-xs() {
        padding-left: 16px;
        padding-right: 16px;
      }
    }

    .agenda-event {
      padding: 0 40px;
      @include md-rd-xs {
        padding: 0;
      }
      .panel {
        margin-right: 80px;
        @include md-rd-md {
          margin-right: 40px;
        }
        @include md-rd-sm {
          margin-right: 0;
        }
        @include md-rd-xs {
          margin-right: 0;
        }
        &:last-child {
          margin-right: 0;
        }
        .activity-list .activity {
          box-sizing: border-box;
          flex-direction: column;
          flex-wrap: nowrap;
          &.chinese {
            height: 300px;
          }
          &.english {
            height: 360px;
          }
          &:last-child {
            height: inherit;
          }
          @include md-rd-md {
            &.chinese {
              height: 240px;
            }
            &.english {
              height: 400px;
            }
          }
          @include md-rd-lt-md {
            &.chinese,
            &.english {
              height: inherit;
            }
          }
        }
      }
      .location {
        margin: 26px 0 20px;
        font-weight: 500;
        color: $color-blue;
        h5 {
          font-size: 18px;
          letter-spacing: 2px;
          margin: 0;
          font-weight: 500;
        }
        span {
          font-size: 12px;
          letter-spacing: 1.3px;
        }
      }
      .activity-list {
        @include md-rd-xs {
          padding-left: 24px;
        }
        ul {
          list-style: inherit;
          padding-left: 18px;
        }
        .activity {
          display: flex;
          flex-wrap: wrap;
          font-size: 12px;
          padding: 6px 0;
          line-height: 2;
          h5 {
            font-weight: 400;
            margin: 0;
            font-size: 12px;
          }
          .time {
            color: $color-blue;
            width: 120px;
            padding-right: 24px;
            background-image: linear-gradient(275deg, #67eefb, #1c8ef7);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            &.full-width {
              width: 100%;
              padding-right: 0;
            }
            @include md-rd-xs {
              width: 100%;
              padding-right: 0;
            }
          }
          .content {
            color: $color-primary;
          }
          .ticket {
            margin-left: 12px;
            color: $color-blue;
            text-decoration: none;
          }
        }
      }
    }

  }

  #review {
    display: block;
    .indicator {
      z-index: 10;
      width: 120px;
      height: 40px;
      padding: 0 8px;
      border-radius: 20px;
      background-image: linear-gradient(313deg, #67eefa, #1c8ef7);
    }
    .photo-carousel {
      position: relative;
      .indicator {
        right: calc(50% + 120px / 2);
        bottom: -20px;
      }
      #main-photo, #next-photo, #main-hidden-photo, #next-hidden-photo {
        @extend .position-absolute;
        background: no-repeat center;
        background-size: cover;
        left: 0;
        height: 100%;
      }
      .main-move, .main-hidden-photo-move, .next-move {
        transition: all 1.0s ease;
      }
      .background-cover {
        @extend .full-size;
      }
      .transition {
        transition: width 1.0s linear, background-color 1.0s linear;
      }
      .cover {
        background-color: rgba(0, 17, 45, 0.54);
      }
      .no-cover {
        background-color: rgba(0, 0, 0, 0);
      }
      // main-hidden
      #main-hidden-photo {
        width: 66vw;
      }
      .main-hidden-photo-original {
        transform: translateX(-66vw);
      }
      .main-hidden-photo-move {
        transform: translateX(0);
      }
      //main-original
      .main-original {
        width: 66vw;
      }
      .main-move {
        transform: translateX(66vw);
        width: 34vw;
        z-index: 8;
      }
      //next-original
      .next-original {
        transform: translateX(66vw);
        width: 34vw;
      }
      .next-move {
        transform: translateX(0);
        width: 66vw;
        z-index: 8;
      }
      //next-hidden
      #next-hidden-photo {
        width: 34vw;
      }
      .next-hidden-photo-original {
        transform: translateX(100vw);
      }
      .next-hidden-photo-move {
        transform: translateX(66vw);
        transition: all 0.9s ease;
      }
      .description {
        @extend .position-absolute;
        z-index: 9;
        right: 0;
        top: calc(100% - 56px);
        width: 50%;
        background: white;
        padding: 24px 20px 24px 72px;
        p {
          margin: 12px 0 0 0;
        }
      }
    }
    .photo-carousel-xs {
      .indicator {
        bottom: calc(-40px / 2);
        left: calc(50% - 120px / 2);
      }
      .picture-container {
        width: calc(100vw * 32);
        img {
          float: left;
          width: 100vw;
        }
        transition: all 1.0s ease;
      }
      .description {
        height: 136px;
        padding: 32px 24px 24px;
        background: white;
        p {
          margin: 20px 0 0 0;
        }
      }
    }

  }

  #team {
    .team-first-row {
      @include md-rd-lt-md() {
        flex-wrap: wrap;
      }
      .summary {
        margin-left: 140px;
        @include md-rd-lt-md() {
          order: 1;
          width: 100%;
          box-sizing: border-box;
          padding: 0 20px;
          text-align: center;
          margin-left: 0;
          margin-top: 40px;
        }
      }
      .team-section-indicator {
        @include md-rd-lt-md() {
          order: 0;
          width: 100%;
        }
      }
    }
  }

  #media {
    padding-bottom: 40px;
    @include md-rd-xs() {
      padding-bottom: 64px;
    }
    .media-list {
      padding: 80px 100px 64px;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(156px, 156px));
      grid-gap: 24px;
      justify-content: center;
      @include md-rd-lt-md() {
        padding: 80px 50px 32px;
      }
      @include md-rd-xs() {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
      }
      > div {
        background: white no-repeat center;
        background-size: contain;
        @include size(156px, 120px);
        @include md-rd-xs() {
          width: 130px;
          height: 100px;
          margin: 12px;
        }
      }
    }
    .sponsor {
      padding: 0 20px;
      text-align: center;
      font-size: 20px;
      line-height: 1.6;
    }
  }

  // 联系我们
  #contact {
    z-index: 2;
    @include md-rd-xs() {
      padding-top: 120px;
    }
    .contact-card {
      height: 600px;
      width: 440px;
      z-index: 4;
      background-image: linear-gradient(345deg, #67eefb, #1c8ef7);
      @include md-rd-xs {
        width: 100%;
      }
      .info-container {
        padding: 96px 0 72px 75px;
      }
      .info-line {
        margin-bottom: 20px;
        p,
        a.email {
          color: $color-white;
          font-size: 16px;
          line-height: 1.5;
          font-weight: 500;
        }
        p {
          margin: 0;
        }
        a {
          text-decoration: none;
        }
      }
      .section-end-bar {
        background: $color-white;
        h3 {
          color: $color-blue;
        }
      }
    }
    .map-wrapper {
      @extend .background-style;
      display: block;
      height: 600px;
      background-image: url("../images/map.jpg");
      margin-top: -60px;
      @include md-rd-xs {
        margin-top: -40px;
        height: 300px;
      }
    }
  }
}
