.team-card {
  height: 120px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 15px 24px 0 rgba(137, 157, 179, 0.12);
  .team-logo-wrapper {
    @include size(120px, 120px);
    padding: 16px;
    border-right: 1px solid $ly-bg-tag;
    div.img {
      @extend %background-style;
      background-size: contain;
      @include size(88px, 88px);
    }
  }
  .team-info {
    padding: 0 16px;
    p {
      font-size: 18px;
    }
    .team-describe {
      font-size: 14px;
      @include limit-lines(2);
      margin-top: 8px;
    }
  }
}



