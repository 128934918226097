.team-apply {
  padding-top: 75px;
  .apply-header {
    max-height: 320px;
    padding: 100px 23px 0 107px;
    background: white;
    @include md-rd-xs {
      padding-left: 25px;
    }
    .line {
      margin: 40px 0;
    }
    .leading-words {
      margin: 0 0 40px 0;
      max-width: 600px;
      font-size: 16px;
      color: $color-secondary;
      @include md-rd-xs {
        text-align: center
      }
    }
    .title {
      height: 48px;
      @include md-rd-xs {
        height: auto;
        width: 100%;
      }
    }
  }
  .apply-body {
    background: $color-background;
    padding: 46px 60px 120px;

    @include md-rd-xs {
      padding: 23px 20px 60px;
    }
    .form-description {
      font-size: 14px;
      max-width: 690px;
    }
    .application-form {
      width: 100%;
      background-color: $color-white;
      margin-top: 40px;
      margin-bottom: 30px;
      padding-top: 80px;
      padding-bottom: 60px;
      @include md-rd-xs {
        padding: 20px;
      }
      @include md-rd-sm {
        padding-left: 60px;
        padding-right: 60px;
      }
      @include md-rd-md {
        max-width: 960px;
        padding-left: 120px;
        padding-right: 120px;
      }
      @include md-rd-gt-md {
        max-width: 960px;
        padding-left: 160px;
        padding-right: 160px;
      }
      &.lang-en {
        .form-field > label {
          width: 173px;
          @include md-rd-xs {
            width: 138px;
          }
        }
      }
      .form-field {
        margin-bottom: 40px;
        @include md-rd-xs {
          margin-bottom: 20px;
        }
        > label {
          width: 104px;
          @include md-rd-xs {
            width: 84px;
            padding-bottom: 10px;
          }
          img {
            height: 20px;
            @include md-rd-xs {
              height: 16px;
            }
            &.adjust {
              @include size(204px, 26px);
              @include md-rd-xs {
                @include size(146px, 20px);
              }
            }
          }
        }
        .input-wrapper {
          border-radius: 4px;
          margin-left: 40px;
          height: 42px;
          background: linear-gradient(163deg, $color-blue, #4178cf, $color-orange);
          @include md-rd-xs {
            margin-left: 0;
          }
        }
        input {
          margin: 1px;
          border: 1px solid transparent;
          border-radius: 4px;
          height: 40px;
          line-height: 24px;
          padding: 0 10px;
          outline: none;
          vertical-align: middle;
        }
      }
    }
    .info {
      font-size: 14px;
      a {
        color: $color-secondary;
        text-decoration: none;
      }
    }
  }
}