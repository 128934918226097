.grid-list-container {
  width: 100%;
  margin: 0 auto;
  max-width: 1280px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 240px);
  grid-gap: 80px 48px;
  align-items: start;
  justify-content: center;
  @include md-rd-xs() {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    > div {
      width: 240px;
      margin-bottom: 48px;
    }
  }
}