@charset "UTF-8";
body {
  font-family: PingFang SC, RobotoDraft, Roboto, "Helvetica Neue", Helvetica, Arial, STHeiti, "华文黑体", "Microsoft YaHei", "微软雅黑", sans-serif;
  background-color: white;
  -webkit-font-smoothing: antialiased; }

button,
select,
html,
textarea,
input {
  font-family: PingFang SC, RobotoDraft, Roboto, "Helvetica Neue", Helvetica, Arial, STHeiti, "华文黑体", "Microsoft YaHei", "微软雅黑", sans-serif; }

:focus {
  outline: none; }

input {
  -webkit-appearance: none; }

ul,
ol {
  list-style: none; }

.full-height {
  height: 100%; }

.full-width, .second-page-general, .speakers-view, .teams-view, .partners-view, .startup-expo-view {
  width: 100%; }

.full-screen {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  width: 100%;
  height: 100%; }

.full-size, .sections #review .photo-carousel .background-cover, .show-big-image {
  width: 100%;
  height: 100%; }

.text-left {
  text-align: left; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.cursor-pointer, .show-big-image img {
  cursor: pointer; }

.no-margin, .show-big-image .close, .show-big-image .arrow {
  margin: 0; }

.position-relative {
  position: relative; }

.position-absolute, .sections #review .photo-carousel #main-photo, .sections #review .photo-carousel #next-photo, .sections #review .photo-carousel #main-hidden-photo, .sections #review .photo-carousel #next-hidden-photo, .sections #review .photo-carousel .description {
  position: absolute; }

.position-absolute-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); }

.no-scroll {
  overflow-y: hidden; }

.team-card .team-logo-wrapper div.img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }

.no-shrink {
  flex-shrink: 0; }

.overflow-hidden {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block; }

.limit-one-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical; }

.background-style, .sections #contact .map-wrapper, .image-slider .image-slider-container .images-wrapper .slider-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }

.color-primary, .round-speaker-profile, .square-team-partner {
  color: rgba(0, 0, 0, 0.87); }

.color-blue {
  color: #0a88ff; }

.color-white, .sections .section-end-bar h3, .speakers-view .top-header-bar h3, .speaker-title .longest-bar > h3,
.speaker-title .second-bar > h3, .second-page-ticket-and-sponsor .more-partner h3 {
  color: #fdfdfd; }

.color-secondary {
  color: rgba(0, 0, 0, 0.54); }

.color-red {
  color: #ea1c43; }

.color-orange {
  color: #f44336; }

.link-blue {
  color: #0a88ff;
  text-decoration: none; }

.icon-color-blue svg {
  fill: #0a88ff; }

[ng-click],
button {
  outline: none;
  cursor: pointer; }
  [ng-click].no-cursor-pointer,
  button.no-cursor-pointer {
    cursor: inherit; }

.button-style {
  border: none;
  padding: 0;
  margin: 0;
  width: 215px;
  height: 64px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url("../images/button-background.png");
  background-color: transparent;
  background-position: 0 4px;
  color: #fdfdfd;
  font-size: 16px;
  letter-spacing: 3px;
  line-height: 0.9;
  text-align: center; }
  .button-style.button-background-alternative {
    background-image: url("../images/button-background-alternative.png"); }
  @media (min-width: 600px) {
    .button-style:hover {
      background-image: url("../images/button-background-hover.png");
      color: #f44336; } }

.line-wrapper, md-dialog {
  border-style: solid;
  border-width: 1px;
  border-image-source: linear-gradient(163deg, #0a88ff, #4178cf, #f44336);
  border-image-slice: 1; }

.line-wrapper-ie {
  border: 1px solid #f44336; }

@media (min-width: 600px) {
  .border-animate-wrapper {
    overflow: hidden; }
    .border-animate-wrapper .border-animate {
      position: relative; }
      .border-animate-wrapper .border-animate:before {
        content: '';
        position: absolute;
        top: -1px;
        right: -1px;
        width: calc(100% + 1px);
        height: 0;
        background-color: transparent;
        border-style: solid;
        border-width: 1px 1px 0 0;
        border-image-source: linear-gradient(163deg, #0a88ff, #4178cf, #f44336);
        border-image-slice: 1;
        transform: translateX(-100%); }
      .border-animate-wrapper .border-animate:after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: -1px;
        width: calc(100% + 1px);
        height: 0;
        background-color: transparent;
        border-style: solid;
        border-width: 0 0 1px 1px;
        border-image-source: linear-gradient(163deg, #0a88ff, #4178cf, #f44336);
        border-image-slice: 1;
        transform: translateX(100%); }
      .border-animate-wrapper .border-animate:hover {
        border-color: transparent;
        border-image-source: none; }
        .border-animate-wrapper .border-animate:hover:before {
          transition: transform .2s linear, height .2s linear .3s;
          transform: translateX(0);
          width: calc(100% + 1px);
          height: calc(100% + 1px); }
        .border-animate-wrapper .border-animate:hover:after {
          transition: transform .2s linear .5s, height .2s linear .7s;
          transform: translateX(0);
          width: calc(100% + 1px);
          height: calc(100% + 1px); } }

a, a:hover, a:visited, a:active {
  text-decoration: none; }

a.email {
  color: #0a88ff;
  text-decoration: none; }

.animate-fade-in.ng-enter, .animate-fade-in.ng-leave, .animate-fade-in.ng-move {
  transition: all 1s ease; }

.animate-fade-in.ng-enter, .animate-fade-in.ng-move, .animate-fade-in.ng-leave.ng-leave-active {
  opacity: 0; }

.animate-fade-in.ng-leave, .animate-fade-in.ng-move.ng-move-active, .animate-fade-in.ng-enter.ng-enter-active {
  opacity: 1; }

.font-16 {
  font-size: 16px;
  font-weight: 400;
  vertical-align: middle;
  line-height: 24px; }

.ticket-button {
  display: block;
  width: 120px;
  height: 35px;
  border-radius: 100px;
  background-image: linear-gradient(309deg, #67eefb, #1c8ef7);
  box-shadow: 0 2px 4px 0 rgba(2, 21, 40, 0.12);
  margin-right: 24px;
  line-height: 35px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: #fdfdfd;
  text-decoration: none; }

.switch-lang {
  width: 48px;
  height: 16px;
  background-image: url("../images/icon-switch-lang.png");
  background-size: cover; }

.header {
  position: fixed;
  z-index: 100;
  top: 0;
  background-color: #fdfdfd;
  box-shadow: 0 2px 4px 0 rgba(2, 21, 40, 0.12);
  width: 100%;
  height: 75px; }
  .header.no-shadow {
    box-shadow: none; }
  .header .logos .liaoyuan,
  .header .logos .summit {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0 center; }
  .header .navs {
    height: 100%; }
    .header .navs a {
      height: 100%;
      line-height: 75px;
      color: rgba(0, 0, 0, 0.54);
      font-size: 14px;
      text-decoration: none;
      padding: 0 8px; }
      .header .navs a:last-child {
        margin-right: 0; }
      .header .navs a.active {
        font-weight: 600;
        color: #0a88ff; }
  .header .menus {
    margin-right: 24px; }
  .header .summit {
    background-image: url("../images/logo-summit.png");
    width: 52px;
    height: 32px;
    margin-left: 40px; }
    @media (max-width: 599px) {
      .header .summit {
        margin-left: 24px; } }
  .header .liaoyuan {
    margin-left: 16px;
    margin-right: 24px;
    background-image: url("../images/zh/slogan-liaoyuan.png");
    width: 32px;
    height: 32px; }
  .header .menu {
    height: 40px;
    width: 48px; }
    .header .menu.close {
      position: relative; }
      .header .menu.close:before, .header .menu.close:after {
        display: block;
        content: '';
        width: 24px;
        height: 4px;
        background-image: linear-gradient(316deg, #67eefb, #1c8ef7);
        transform-origin: center;
        position: absolute;
        top: 20px;
        right: 12px; }
      .header .menu.close:before {
        transform: rotate(-45deg); }
      .header .menu.close:after {
        transform: rotate(45deg); }
    .header .menu.hamburger {
      display: flex;
      justify-content: center;
      align-items: center; }
      .header .menu.hamburger .hamburger-item {
        width: 24px;
        height: 4px;
        background-image: linear-gradient(316deg, #67eefb, #1c8ef7); }
        .header .menu.hamburger .hamburger-item:before, .header .menu.hamburger .hamburger-item:after {
          content: '';
          display: block;
          width: 24px;
          height: 4px;
          background-image: linear-gradient(316deg, #67eefb, #1c8ef7); }
        .header .menu.hamburger .hamburger-item:before {
          transform: translateY(-8px); }
        .header .menu.hamburger .hamburger-item:after {
          transform: translateY(4px); }

.sidebar {
  position: fixed;
  z-index: 99;
  top: -100vh;
  left: 0;
  right: 0;
  padding-top: 75px;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  max-width: 1920px;
  max-height: 100vh;
  background-color: #fdfdfd;
  transition: top .5s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .sidebar.show {
    top: 0; }
  .sidebar .sidebar-nav {
    display: flex;
    flex-direction: column;
    justify-content: space-around; }
    .sidebar .sidebar-nav > a {
      width: 100%;
      padding: 12px;
      text-align: center;
      font-size: 14px;
      line-height: 1.43;
      letter-spacing: 0.8px;
      color: rgba(0, 0, 0, 0.54);
      text-decoration: none;
      display: block; }
      .sidebar .sidebar-nav > a.active {
        font-weight: 600;
        color: #0a88ff; }
  .sidebar .actions {
    margin-bottom: 48px;
    padding: 12px 0; }
    .sidebar .actions .ticket-button {
      margin: 0 0 12px 0; }
    .sidebar .actions .switch-lang-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 12px; }

.overlay {
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); }

.wx-qr {
  width: 200px;
  height: 200px;
  max-width: initial;
  background-color: white;
  border-radius: 4px;
  position: absolute;
  bottom: 180%;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px; }
  .wx-qr img {
    width: 100%; }

.footer {
  background-color: rgba(8, 33, 59, 0.87);
  color: white;
  padding: 100px 50px 48px; }
  .footer.should-bump {
    transform: translateY(-60px); }
    @media (max-width: 599px) {
      .footer.should-bump {
        transform: translateY(0); } }
  @media (max-width: 599px) {
    .footer {
      padding-left: 24px;
      padding-right: 24px; } }
  .footer a {
    color: inherit;
    text-decoration: none; }
  .footer .logo {
    display: block;
    width: 180px; }
  .footer .sub-menus {
    margin: 40px 0 28px 0;
    font-size: 12px; }
    .footer .sub-menus ul {
      display: flex;
      justify-content: center;
      padding: 0; }
      .footer .sub-menus ul li {
        text-align: center;
        margin: 12px; }
        @media (max-width: 599px) {
          .footer .sub-menus ul li {
            margin: 8px; } }
  .footer .copyright {
    font-size: 12px; }

.sections {
  padding-top: 75px;
  transition: padding-top 0.3s ease;
  background-color: #f7f7f7;
  overflow-x: hidden; }
  .sections .scroll-indicator {
    position: fixed;
    right: 24px;
    top: calc(50% + 50px);
    transform: translateY(-50%);
    z-index: 10; }
    .sections .scroll-indicator > a {
      display: block;
      text-decoration: none;
      margin-bottom: 24px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-image: linear-gradient(340deg, #67eefb, #1c8ef7);
      transition: all 0.6s ease; }
      .sections .scroll-indicator > a:hover {
        transform: scale(1.2); }
      .sections .scroll-indicator > a:last-child {
        margin-bottom: 0; }
      .sections .scroll-indicator > a.active {
        transform-origin: center center;
        transform: translateX(8px);
        width: 24px;
        margin-left: -16px;
        border-radius: 100px;
        background-image: linear-gradient(310deg, #67eefb, #1c8ef7); }
  .sections .screen-height {
    min-height: 400px;
    max-height: 1024px;
    height: calc(100vh - 75px); }
    @media (max-width: 599px) {
      .sections .screen-height {
        height: calc(100vh - 50px); } }
  .sections > section {
    max-width: 1920px;
    margin: 0 auto; }
  .sections .section-indicator-title {
    width: 307px;
    height: 120px;
    border-radius: 100px;
    background-image: linear-gradient(18deg, #67eefb, #1c8ef7); }
  .sections .section-end-bar {
    margin-top: 64px;
    width: 360px;
    height: 64px;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    background-image: linear-gradient(36deg, #67eefb, #1c8ef7);
    cursor: pointer; }
    @media (max-width: 599px) {
      .sections .section-end-bar {
        width: 300px; } }
    .sections .section-end-bar h3 {
      margin: 0;
      font-size: 24px;
      font-weight: 600; }
  .sections .grid-list-container {
    padding-top: 64px;
    padding-bottom: 64px; }
  .sections #first {
    background-image: url("../images/background.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 -125px;
    position: relative;
    padding-bottom: 60px; }
    @media (max-width: 599px) {
      .sections #first {
        background-size: contain;
        background-position-y: 0; } }
    .sections #first .review {
      position: absolute;
      right: 128px;
      top: 73px; }
      .sections #first .review > img {
        width: 107px; }
        @media (max-width: 599px) {
          .sections #first .review > img {
            width: 64px; } }
      @media (max-width: 959px) {
        .sections #first .review {
          right: 24px;
          top: 48px; } }
    .sections #first .title-wrapper {
      z-index: 20;
      margin-bottom: 75px;
      margin-left: 75px; }
      @media (max-width: 599px) {
        .sections #first .title-wrapper {
          margin-bottom: 20px;
          margin-left: 20px; } }
    .sections #first .title-zh img {
      height: 180px;
      width: auto; }
      @media (min-width: 600px) and (max-width: 959px) {
        .sections #first .title-zh img {
          width: 300px;
          height: auto; } }
      @media (max-width: 599px) {
        .sections #first .title-zh img {
          width: 200px;
          height: auto; } }
    @media (min-width: 960px) {
      .sections #first .title-zh .text-wrapper {
        margin-left: -12px; } }
    .sections #first .title-zh .title {
      color: rgba(0, 0, 0, 0.87);
      font-size: 48px;
      font-weight: bold; }
      @media (min-width: 600px) and (max-width: 959px) {
        .sections #first .title-zh .title {
          font-size: 72px; } }
      @media (min-width: 960px) {
        .sections #first .title-zh .title {
          font-size: 102px;
          font-weight: bold;
          line-height: 1.18;
          letter-spacing: 7.3px; } }
    .sections #first .title-zh .sub-title {
      color: rgba(0, 0, 0, 0.87);
      margin: 0;
      font-size: 22px;
      font-weight: 300;
      letter-spacing: 10.9px; }
      @media (min-width: 600px) and (max-width: 959px) {
        .sections #first .title-zh .sub-title {
          font-size: 36px;
          letter-spacing: 16px;
          margin-top: 12px; } }
      @media (min-width: 960px) {
        .sections #first .title-zh .sub-title {
          transform: translateX(-44px);
          font-size: 52px;
          line-height: 0.92;
          letter-spacing: 24px;
          margin-top: 12px; } }
    .sections #first .title-en img {
      width: 600px; }
      @media (min-width: 600px) and (max-width: 959px) {
        .sections #first .title-en img {
          width: 400px; } }
      @media (max-width: 599px) {
        .sections #first .title-en img {
          width: 280px; } }
    .sections #first .title-en .text-wrapper div {
      font-size: 22px;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.87); }
      @media (min-width: 600px) and (max-width: 959px) {
        .sections #first .title-en .text-wrapper div {
          font-size: 48px; } }
      @media (min-width: 960px) {
        .sections #first .title-en .text-wrapper div {
          font-size: 72px;
          line-height: 1.67; } }
    .sections #first .info-bar {
      width: calc(100% - 128px);
      background-image: linear-gradient(69deg, #67eefb, #1c8ef7);
      box-sizing: border-box;
      color: #fdfdfd;
      z-index: 10; }
    .sections #first .info-gt-xs {
      height: 64px;
      border-top-right-radius: 100px;
      border-bottom-right-radius: 100px;
      font-size: 24px;
      font-weight: 600;
      padding-left: 120px;
      max-width: 960px; }
      @media (max-width: 959px) {
        .sections #first .info-gt-xs {
          font-size: 16px;
          padding-left: 16px;
          width: calc(100% - 48px); } }
      .sections #first .info-gt-xs div {
        display: flex;
        align-items: center;
        padding: 0 40px 0 20px;
        position: relative;
        height: 64px; }
        @media (max-width: 959px) {
          .sections #first .info-gt-xs div {
            padding-right: 20px; } }
        .sections #first .info-gt-xs div:not(.purchase):before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 7px solid #f5f5f5; }
      .sections #first .info-gt-xs .purchase {
        cursor: pointer; }
    .sections #first .info-xs {
      width: 100%;
      padding: 12px 16px; }
      .sections #first .info-xs > div:first-child {
        margin-bottom: 12px; }
    .sections #first .info-purchase-xs {
      width: 100%;
      margin-top: 20px; }
      .sections #first .info-purchase-xs > div {
        padding: 8px 32px;
        width: 65%;
        max-width: 200px;
        border-top-right-radius: 100px;
        border-bottom-right-radius: 100px;
        background-image: linear-gradient(28deg, #67eefb, #1c8ef7);
        color: #fdfdfd; }
  .sections #partner {
    padding-top: 75px; }
    @media (max-width: 599px) {
      .sections #partner {
        padding-top: 120px; } }
    .sections #partner .introduction {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center; }
      @media (max-width: 699px) {
        .sections #partner .introduction {
          flex-direction: column;
          justify-content: center; } }
      .sections #partner .introduction .liaoyuan {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 160px; }
        @media (max-width: 699px) {
          .sections #partner .introduction .liaoyuan {
            margin-top: 24px;
            order: 1;
            flex-direction: column; } }
        @media (max-width: 959px) {
          .sections #partner .introduction .liaoyuan {
            margin-left: 0; } }
        .sections #partner .introduction .liaoyuan h2 {
          margin: 0;
          font-size: 36px;
          font-weight: 400;
          letter-spacing: 16.6px;
          color: rgba(0, 0, 0, 0.87); }
        .sections #partner .introduction .liaoyuan h4 {
          margin: 18px 0 0 0;
          background-image: linear-gradient(296deg, #67eefb, #1c8ef7);
          font-size: 24px;
          font-weight: 500;
          letter-spacing: 6px;
          text-align: left;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent; }
        .sections #partner .introduction .liaoyuan .logo-wrapper {
          background-image: url("../images/主办方@2x.png");
          background-size: cover;
          width: 250px;
          height: 250px; }
          .sections #partner .introduction .liaoyuan .logo-wrapper img {
            display: block;
            width: 157px;
            margin-left: 53px;
            margin-top: 59px; }
      @media (max-width: 699px) {
        .sections #partner .introduction .section-title-wrapper {
          width: 100%; } }
    .sections #partner .liaoyuan-description {
      white-space: pre-wrap;
      max-width: 776px;
      color: rgba(0, 0, 0, 0.87);
      font-size: 14px;
      line-height: 1.71;
      padding: 60px 0 60px 160px; }
      @media (min-width: 600px) and (max-width: 959px) {
        .sections #partner .liaoyuan-description {
          padding-left: 24px; } }
      @media (max-width: 699px) {
        .sections #partner .liaoyuan-description {
          padding: 24px 24px 80px; } }
    .sections #partner .more {
      margin-top: 64px; }
      @media (max-width: 700px) {
        .sections #partner .more {
          flex-wrap: wrap; } }
      .sections #partner .more .more-button .section-end-bar {
        margin-top: 0; }
      @media (max-width: 700px) {
        .sections #partner .more .more-button {
          min-width: 100%; } }
      @media (max-width: 700px) {
        .sections #partner .more .more-partner {
          margin-bottom: 50px; } }
      .sections #partner .more .sponsor-us {
        margin: 0 5% 0 20px;
        font-size: 20px;
        max-width: 700px; }
        .sections #partner .more .sponsor-us span {
          letter-spacing: 1.1px;
          line-height: 30px; }
        @media (max-width: 700px) {
          .sections #partner .more .sponsor-us {
            text-align: center;
            margin-right: 20px; } }
  .sections #speaker {
    padding-top: 160px; }
  .sections #agenda,
  .sections #review,
  .sections #team,
  .sections #media,
  .sections #contact {
    padding-top: 120px; }
  .sections #agenda .divider-title {
    position: relative;
    color: #fdfdfd;
    border-radius: 100px;
    background-image: linear-gradient(302deg, #67eefb, #1c8ef7); }
    @media (max-width: 599px) {
      .sections #agenda .divider-title {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; } }
  .sections #agenda .date-divider {
    padding-left: 140px;
    margin-top: 36px; }
    @media (min-width: 600px) and (max-width: 959px) {
      .sections #agenda .date-divider {
        padding-left: 32px;
        margin-top: 36px; } }
    @media (max-width: 599px) {
      .sections #agenda .date-divider {
        padding-left: 0;
        margin-top: 24px; } }
    .sections #agenda .date-divider .date-title {
      padding: 12px 48px 12px 24px;
      height: 64px; }
      .sections #agenda .date-divider .date-title h4 {
        font-size: 32px;
        font-weight: 500;
        letter-spacing: 1.6px;
        margin: 0 8px 0 0; }
      .sections #agenda .date-divider .date-title span {
        font-size: 18px; }
    .sections #agenda .date-divider .separator {
      height: 2px;
      border-top: 1px solid #0a88ff; }
  .sections #agenda .day-divider {
    margin-top: 32px; }
    .sections #agenda .day-divider .day-title {
      padding: 8px 12px;
      height: 32px;
      font-size: 12px; }
      @media (max-width: 599px) {
        .sections #agenda .day-divider .day-title {
          border-top-left-radius: 100px;
          border-bottom-left-radius: 100px; } }
    .sections #agenda .day-divider .dashed-separator {
      height: 2px;
      border-top: 1px dashed #0a88ff; }
  .sections #agenda .sep-29th,
  .sections #agenda .sep-30th,
  .sections #agenda .oct-1st {
    padding-left: 200px; }
    @media (min-width: 600px) and (max-width: 959px) {
      .sections #agenda .sep-29th,
      .sections #agenda .sep-30th,
      .sections #agenda .oct-1st {
        padding-left: 88px; } }
    @media (max-width: 599px) {
      .sections #agenda .sep-29th,
      .sections #agenda .sep-30th,
      .sections #agenda .oct-1st {
        padding-left: 16px;
        padding-right: 16px; } }
  .sections #agenda .agenda-event {
    padding: 0 40px; }
    @media (max-width: 599px) {
      .sections #agenda .agenda-event {
        padding: 0; } }
    .sections #agenda .agenda-event .panel {
      margin-right: 80px; }
      @media (min-width: 960px) and (max-width: 1279px) {
        .sections #agenda .agenda-event .panel {
          margin-right: 40px; } }
      @media (min-width: 600px) and (max-width: 959px) {
        .sections #agenda .agenda-event .panel {
          margin-right: 0; } }
      @media (max-width: 599px) {
        .sections #agenda .agenda-event .panel {
          margin-right: 0; } }
      .sections #agenda .agenda-event .panel:last-child {
        margin-right: 0; }
      .sections #agenda .agenda-event .panel .activity-list .activity {
        box-sizing: border-box;
        flex-direction: column;
        flex-wrap: nowrap; }
        .sections #agenda .agenda-event .panel .activity-list .activity.chinese {
          height: 300px; }
        .sections #agenda .agenda-event .panel .activity-list .activity.english {
          height: 360px; }
        .sections #agenda .agenda-event .panel .activity-list .activity:last-child {
          height: inherit; }
        @media (min-width: 960px) and (max-width: 1279px) {
          .sections #agenda .agenda-event .panel .activity-list .activity.chinese {
            height: 240px; }
          .sections #agenda .agenda-event .panel .activity-list .activity.english {
            height: 400px; } }
        @media (max-width: 959px) {
          .sections #agenda .agenda-event .panel .activity-list .activity.chinese, .sections #agenda .agenda-event .panel .activity-list .activity.english {
            height: inherit; } }
    .sections #agenda .agenda-event .location {
      margin: 26px 0 20px;
      font-weight: 500;
      color: #0a88ff; }
      .sections #agenda .agenda-event .location h5 {
        font-size: 18px;
        letter-spacing: 2px;
        margin: 0;
        font-weight: 500; }
      .sections #agenda .agenda-event .location span {
        font-size: 12px;
        letter-spacing: 1.3px; }
    @media (max-width: 599px) {
      .sections #agenda .agenda-event .activity-list {
        padding-left: 24px; } }
    .sections #agenda .agenda-event .activity-list ul {
      list-style: inherit;
      padding-left: 18px; }
    .sections #agenda .agenda-event .activity-list .activity {
      display: flex;
      flex-wrap: wrap;
      font-size: 12px;
      padding: 6px 0;
      line-height: 2; }
      .sections #agenda .agenda-event .activity-list .activity h5 {
        font-weight: 400;
        margin: 0;
        font-size: 12px; }
      .sections #agenda .agenda-event .activity-list .activity .time {
        color: #0a88ff;
        width: 120px;
        padding-right: 24px;
        background-image: linear-gradient(275deg, #67eefb, #1c8ef7);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; }
        .sections #agenda .agenda-event .activity-list .activity .time.full-width, .sections #agenda .agenda-event .activity-list .activity .time.second-page-general, .sections #agenda .agenda-event .activity-list .activity .time.speakers-view, .sections #agenda .agenda-event .activity-list .activity .time.teams-view, .sections #agenda .agenda-event .activity-list .activity .time.partners-view, .sections #agenda .agenda-event .activity-list .activity .time.startup-expo-view {
          width: 100%;
          padding-right: 0; }
        @media (max-width: 599px) {
          .sections #agenda .agenda-event .activity-list .activity .time {
            width: 100%;
            padding-right: 0; } }
      .sections #agenda .agenda-event .activity-list .activity .content {
        color: rgba(0, 0, 0, 0.87); }
      .sections #agenda .agenda-event .activity-list .activity .ticket {
        margin-left: 12px;
        color: #0a88ff;
        text-decoration: none; }
  .sections #review {
    display: block; }
    .sections #review .indicator {
      z-index: 10;
      width: 120px;
      height: 40px;
      padding: 0 8px;
      border-radius: 20px;
      background-image: linear-gradient(313deg, #67eefa, #1c8ef7); }
    .sections #review .photo-carousel {
      position: relative; }
      .sections #review .photo-carousel .indicator {
        right: calc(50% + 120px / 2);
        bottom: -20px; }
      .sections #review .photo-carousel #main-photo, .sections #review .photo-carousel #next-photo, .sections #review .photo-carousel #main-hidden-photo, .sections #review .photo-carousel #next-hidden-photo {
        background: no-repeat center;
        background-size: cover;
        left: 0;
        height: 100%; }
      .sections #review .photo-carousel .main-move, .sections #review .photo-carousel .main-hidden-photo-move, .sections #review .photo-carousel .next-move {
        transition: all 1.0s ease; }
      .sections #review .photo-carousel .transition {
        transition: width 1.0s linear, background-color 1.0s linear; }
      .sections #review .photo-carousel .cover {
        background-color: rgba(0, 17, 45, 0.54); }
      .sections #review .photo-carousel .no-cover {
        background-color: transparent; }
      .sections #review .photo-carousel #main-hidden-photo {
        width: 66vw; }
      .sections #review .photo-carousel .main-hidden-photo-original {
        transform: translateX(-66vw); }
      .sections #review .photo-carousel .main-hidden-photo-move {
        transform: translateX(0); }
      .sections #review .photo-carousel .main-original {
        width: 66vw; }
      .sections #review .photo-carousel .main-move {
        transform: translateX(66vw);
        width: 34vw;
        z-index: 8; }
      .sections #review .photo-carousel .next-original {
        transform: translateX(66vw);
        width: 34vw; }
      .sections #review .photo-carousel .next-move {
        transform: translateX(0);
        width: 66vw;
        z-index: 8; }
      .sections #review .photo-carousel #next-hidden-photo {
        width: 34vw; }
      .sections #review .photo-carousel .next-hidden-photo-original {
        transform: translateX(100vw); }
      .sections #review .photo-carousel .next-hidden-photo-move {
        transform: translateX(66vw);
        transition: all 0.9s ease; }
      .sections #review .photo-carousel .description {
        z-index: 9;
        right: 0;
        top: calc(100% - 56px);
        width: 50%;
        background: white;
        padding: 24px 20px 24px 72px; }
        .sections #review .photo-carousel .description p {
          margin: 12px 0 0 0; }
    .sections #review .photo-carousel-xs .indicator {
      bottom: calc(-40px / 2);
      left: calc(50% - 120px / 2); }
    .sections #review .photo-carousel-xs .picture-container {
      width: calc(100vw * 32);
      transition: all 1.0s ease; }
      .sections #review .photo-carousel-xs .picture-container img {
        float: left;
        width: 100vw; }
    .sections #review .photo-carousel-xs .description {
      height: 136px;
      padding: 32px 24px 24px;
      background: white; }
      .sections #review .photo-carousel-xs .description p {
        margin: 20px 0 0 0; }
  @media (max-width: 959px) {
    .sections #team .team-first-row {
      flex-wrap: wrap; } }
  .sections #team .team-first-row .summary {
    margin-left: 140px; }
    @media (max-width: 959px) {
      .sections #team .team-first-row .summary {
        order: 1;
        width: 100%;
        box-sizing: border-box;
        padding: 0 20px;
        text-align: center;
        margin-left: 0;
        margin-top: 40px; } }
  @media (max-width: 959px) {
    .sections #team .team-first-row .team-section-indicator {
      order: 0;
      width: 100%; } }
  .sections #media {
    padding-bottom: 40px; }
    @media (max-width: 599px) {
      .sections #media {
        padding-bottom: 64px; } }
    .sections #media .media-list {
      padding: 80px 100px 64px;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(156px, 156px));
      grid-gap: 24px;
      justify-content: center; }
      @media (max-width: 959px) {
        .sections #media .media-list {
          padding: 80px 50px 32px; } }
      @media (max-width: 599px) {
        .sections #media .media-list {
          display: flex;
          justify-content: center;
          flex-wrap: wrap; } }
      .sections #media .media-list > div {
        background: white no-repeat center;
        background-size: contain;
        width: 156px;
        height: 120px; }
        @media (max-width: 599px) {
          .sections #media .media-list > div {
            width: 130px;
            height: 100px;
            margin: 12px; } }
    .sections #media .sponsor {
      padding: 0 20px;
      text-align: center;
      font-size: 20px;
      line-height: 1.6; }
  .sections #contact {
    z-index: 2; }
    @media (max-width: 599px) {
      .sections #contact {
        padding-top: 120px; } }
    .sections #contact .contact-card {
      height: 600px;
      width: 440px;
      z-index: 4;
      background-image: linear-gradient(345deg, #67eefb, #1c8ef7); }
      @media (max-width: 599px) {
        .sections #contact .contact-card {
          width: 100%; } }
      .sections #contact .contact-card .info-container {
        padding: 96px 0 72px 75px; }
      .sections #contact .contact-card .info-line {
        margin-bottom: 20px; }
        .sections #contact .contact-card .info-line p,
        .sections #contact .contact-card .info-line a.email {
          color: #fdfdfd;
          font-size: 16px;
          line-height: 1.5;
          font-weight: 500; }
        .sections #contact .contact-card .info-line p {
          margin: 0; }
        .sections #contact .contact-card .info-line a {
          text-decoration: none; }
      .sections #contact .contact-card .section-end-bar {
        background: #fdfdfd; }
        .sections #contact .contact-card .section-end-bar h3 {
          color: #0a88ff; }
    .sections #contact .map-wrapper {
      display: block;
      height: 600px;
      background-image: url("../images/map.jpg");
      margin-top: -60px; }
      @media (max-width: 599px) {
        .sections #contact .map-wrapper {
          margin-top: -40px;
          height: 300px; } }

.team-apply {
  padding-top: 75px; }
  .team-apply .apply-header {
    max-height: 320px;
    padding: 100px 23px 0 107px;
    background: white; }
    @media (max-width: 599px) {
      .team-apply .apply-header {
        padding-left: 25px; } }
    .team-apply .apply-header .line {
      margin: 40px 0; }
    .team-apply .apply-header .leading-words {
      margin: 0 0 40px 0;
      max-width: 600px;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.54); }
      @media (max-width: 599px) {
        .team-apply .apply-header .leading-words {
          text-align: center; } }
    .team-apply .apply-header .title {
      height: 48px; }
      @media (max-width: 599px) {
        .team-apply .apply-header .title {
          height: auto;
          width: 100%; } }
  .team-apply .apply-body {
    background: #f3f4f7;
    padding: 46px 60px 120px; }
    @media (max-width: 599px) {
      .team-apply .apply-body {
        padding: 23px 20px 60px; } }
    .team-apply .apply-body .form-description {
      font-size: 14px;
      max-width: 690px; }
    .team-apply .apply-body .application-form {
      width: 100%;
      background-color: #fdfdfd;
      margin-top: 40px;
      margin-bottom: 30px;
      padding-top: 80px;
      padding-bottom: 60px; }
      @media (max-width: 599px) {
        .team-apply .apply-body .application-form {
          padding: 20px; } }
      @media (min-width: 600px) and (max-width: 959px) {
        .team-apply .apply-body .application-form {
          padding-left: 60px;
          padding-right: 60px; } }
      @media (min-width: 960px) and (max-width: 1279px) {
        .team-apply .apply-body .application-form {
          max-width: 960px;
          padding-left: 120px;
          padding-right: 120px; } }
      @media (min-width: 1280px) {
        .team-apply .apply-body .application-form {
          max-width: 960px;
          padding-left: 160px;
          padding-right: 160px; } }
      .team-apply .apply-body .application-form.lang-en .form-field > label {
        width: 173px; }
        @media (max-width: 599px) {
          .team-apply .apply-body .application-form.lang-en .form-field > label {
            width: 138px; } }
      .team-apply .apply-body .application-form .form-field {
        margin-bottom: 40px; }
        @media (max-width: 599px) {
          .team-apply .apply-body .application-form .form-field {
            margin-bottom: 20px; } }
        .team-apply .apply-body .application-form .form-field > label {
          width: 104px; }
          @media (max-width: 599px) {
            .team-apply .apply-body .application-form .form-field > label {
              width: 84px;
              padding-bottom: 10px; } }
          .team-apply .apply-body .application-form .form-field > label img {
            height: 20px; }
            @media (max-width: 599px) {
              .team-apply .apply-body .application-form .form-field > label img {
                height: 16px; } }
            .team-apply .apply-body .application-form .form-field > label img.adjust {
              width: 204px;
              height: 26px; }
              @media (max-width: 599px) {
                .team-apply .apply-body .application-form .form-field > label img.adjust {
                  width: 146px;
                  height: 20px; } }
        .team-apply .apply-body .application-form .form-field .input-wrapper {
          border-radius: 4px;
          margin-left: 40px;
          height: 42px;
          background: linear-gradient(163deg, #0a88ff, #4178cf, #f44336); }
          @media (max-width: 599px) {
            .team-apply .apply-body .application-form .form-field .input-wrapper {
              margin-left: 0; } }
        .team-apply .apply-body .application-form .form-field input {
          margin: 1px;
          border: 1px solid transparent;
          border-radius: 4px;
          height: 40px;
          line-height: 24px;
          padding: 0 10px;
          outline: none;
          vertical-align: middle; }
    .team-apply .apply-body .info {
      font-size: 14px; }
      .team-apply .apply-body .info a {
        color: rgba(0, 0, 0, 0.54);
        text-decoration: none; }

.apply-confirmation {
  padding-top: 75px; }
  @media (max-width: 599px) {
    .apply-confirmation {
      padding-top: 50px; } }
  .apply-confirmation .confirmation-header {
    max-height: 320px;
    padding: 100px 0 0 100px;
    background-color: #fdfdfd; }
    @media (max-width: 599px) {
      .apply-confirmation .confirmation-header {
        padding: 48px 16px 40px; } }
    .apply-confirmation .confirmation-header img.title {
      width: auto;
      height: 48px; }
      @media (max-width: 599px) {
        .apply-confirmation .confirmation-header img.title {
          width: auto;
          height: 36px; } }
    .apply-confirmation .confirmation-header .line {
      margin: 40px 0; }
    .apply-confirmation .confirmation-header .leading-words {
      margin-top: 0;
      margin-bottom: 40px;
      max-width: 600px;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.54); }
      @media (max-width: 599px) {
        .apply-confirmation .confirmation-header .leading-words {
          text-align: center;
          width: 260px;
          margin-bottom: 0; } }
  .apply-confirmation .confirmation-body {
    padding: 46px 60px 120px; }
    @media (max-width: 599px) {
      .apply-confirmation .confirmation-body {
        padding: 16px; } }
    @media (min-width: 600px) and (max-width: 959px) {
      .apply-confirmation .confirmation-body {
        padding: 36px 32px 48px; } }
    .apply-confirmation .confirmation-body .subtitle {
      height: auto;
      max-height: 24px; }
      @media (max-width: 599px) {
        .apply-confirmation .confirmation-body .subtitle {
          height: 15px; } }
    .apply-confirmation .confirmation-body img {
      max-width: 100%;
      margin-top: 4px;
      margin-bottom: 12px; }
    .apply-confirmation .confirmation-body article {
      width: 100%;
      max-width: 960px;
      padding: 50px 100px;
      background-color: #fdfdfd; }
      @media (max-width: 599px) {
        .apply-confirmation .confirmation-body article {
          padding: 30px 10px; } }
      @media (min-width: 600px) and (max-width: 959px) {
        .apply-confirmation .confirmation-body article {
          padding: 48px 60px 48px; } }
      .apply-confirmation .confirmation-body article .booth-info {
        margin-bottom: 30px; }
      @media (max-width: 599px) {
        .apply-confirmation .confirmation-body article ul {
          padding-left: 30px; } }
      .apply-confirmation .confirmation-body article ul li {
        list-style: circle;
        padding-bottom: 28px; }
        .apply-confirmation .confirmation-body article ul li img {
          margin: 0 0 -3px 0; }
      .apply-confirmation .confirmation-body article ol {
        padding-left: 20px; }
        .apply-confirmation .confirmation-body article ol li {
          list-style: decimal;
          padding-bottom: 26px; }

.second-page-general, .speakers-view, .teams-view, .partners-view, .startup-expo-view {
  padding-top: 75px;
  padding-bottom: 75px; }
  .second-page-general .grid-list-container, .speakers-view .grid-list-container, .teams-view .grid-list-container, .partners-view .grid-list-container, .startup-expo-view .grid-list-container {
    padding-top: 64px;
    padding-bottom: 64px; }

.speakers-view .top-header-bar {
  height: 120px;
  transform: translateY(-92px); }
  @media (max-width: 599px) {
    .speakers-view .top-header-bar {
      height: 200px; } }
  .speakers-view .top-header-bar div {
    height: 48px;
    box-sizing: border-box;
    border-radius: 100px;
    cursor: pointer; }
  .speakers-view .top-header-bar h3 {
    margin: 0 0 -4px 0;
    font-size: 14px;
    letter-spacing: 0.8px;
    border-bottom: 4px solid transparent; }
    .speakers-view .top-header-bar h3.current {
      border-bottom-color: #fdfdfd; }
  .speakers-view .top-header-bar .longest-bar,
  .speakers-view .top-header-bar .second-bar {
    display: flex;
    align-items: center;
    padding-left: 72px; }
  .speakers-view .top-header-bar .longest-bar {
    position: relative;
    width: 336px;
    background-image: linear-gradient(42deg, #67eefb, #1c8ef7); }
  .speakers-view .top-header-bar .second-bar {
    position: absolute;
    right: 0;
    z-index: 1;
    width: 177px;
    background-image: linear-gradient(25deg, #67eefb, #1c8ef7); }

.speakers-view .speakers-body {
  margin-top: 0;
  padding-top: 0; }

.dialog-custom md-dialog-content {
  padding: 20px; }

.apply-dialog {
  max-width: 400px;
  box-sizing: border-box; }
  @media (max-width: 599px) {
    .apply-dialog {
      max-width: 320px; } }
  .apply-dialog md-dialog-content {
    padding: 80px 40px 30px; }
  .apply-dialog md-dialog-actions {
    padding-bottom: 12px; }
  .apply-dialog p {
    font-size: 16px; }

.show-big-image {
  max-width: 100%;
  max-height: 100%;
  min-width: 48px;
  min-height: 48px;
  background-color: rgba(0, 0, 0, 0.54);
  box-shadow: none;
  border-radius: 0; }
  .show-big-image img {
    max-width: 80vw;
    max-height: 80vh;
    z-index: 10; }
  .show-big-image .close {
    z-index: 10;
    top: 0;
    right: 0;
    width: 48px;
    height: 48px; }
    .show-big-image .close md-icon {
      width: 32px;
      height: 32px;
      min-width: 32px;
      min-height: 32px; }
  .show-big-image .arrow {
    width: 72px;
    height: 72px;
    width: 72px;
    height: 72px; }
    .show-big-image .arrow.left, .show-big-image .arrow.right {
      transform: translateY(-50%);
      top: 50%;
      z-index: 10; }
    .show-big-image .arrow.left {
      left: 20px; }
    .show-big-image .arrow.right {
      right: 20px; }
    .show-big-image .arrow md-icon {
      width: 48px;
      height: 48px;
      min-width: 48px;
      min-height: 48px; }
  .show-big-image .page-index {
    text-align: center;
    bottom: 10px; }
  .show-big-image .index {
    margin-top: 24px;
    text-align: center; }
    @media (max-width: 599px) {
      .show-big-image .index {
        margin-top: 0; } }
  .show-big-image md-progress-circular {
    z-index: 0; }
  .show-big-image .page-index {
    position: fixed;
    text-align: center;
    bottom: 10px; }

.grid-list-container {
  width: 100%;
  margin: 0 auto;
  max-width: 1280px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 240px);
  grid-gap: 80px 48px;
  align-items: start;
  justify-content: center; }
  @media (max-width: 599px) {
    .grid-list-container {
      display: flex;
      justify-content: center;
      flex-wrap: wrap; }
      .grid-list-container > div {
        width: 240px;
        margin-bottom: 48px; } }

.speaker-card {
  position: relative;
  padding-left: 55px;
  padding-top: 25px;
  width: 300px;
  height: 205px;
  box-sizing: border-box; }
  @media (min-width: 600px) and (max-width: 959px) {
    .speaker-card {
      width: 260px; } }
  .speaker-card .speaker-avatar {
    position: absolute;
    left: 0;
    top: 0;
    width: 115px;
    height: 115px;
    box-sizing: border-box;
    box-shadow: 10px 10px 15px -6px rgba(0, 0, 0, 0.34); }
    @media (min-width: 600px) and (max-width: 959px) {
      .speaker-card .speaker-avatar {
        width: 75px;
        height: 75px; } }
    .speaker-card .speaker-avatar img {
      width: 115px;
      height: 115px; }
      @media (min-width: 600px) and (max-width: 959px) {
        .speaker-card .speaker-avatar img {
          width: 75px;
          height: 75px; } }
  .speaker-card .speaker-detail {
    padding: 37px 19px 34px 76px;
    width: 245px;
    height: 180px;
    box-sizing: border-box;
    background-color: white;
    box-shadow: -5px 10px 28px 0 rgba(0, 0, 0, 0.12); }
    @media (min-width: 600px) and (max-width: 959px) {
      .speaker-card .speaker-detail {
        width: 205px;
        padding-left: 36px; } }
    .speaker-card .speaker-detail .speaker-name {
      font-size: 16px;
      line-height: 1.13;
      color: rgba(0, 0, 0, 0.87); }
    .speaker-card .speaker-detail .speaker-description {
      font-size: 12px; }

.team-card {
  height: 120px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 15px 24px 0 rgba(137, 157, 179, 0.12); }
  .team-card .team-logo-wrapper {
    width: 120px;
    height: 120px;
    padding: 16px;
    border-right: 1px solid rgba(0, 0, 0, 0.04); }
    .team-card .team-logo-wrapper div.img {
      background-size: contain;
      width: 88px;
      height: 88px; }
  .team-card .team-info {
    padding: 0 16px; }
    .team-card .team-info p {
      font-size: 18px; }
    .team-card .team-info .team-describe {
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin-top: 8px; }

.image-slider {
  position: relative;
  max-width: 960px;
  width: 100%; }
  .image-slider .hint {
    font-size: 16px;
    margin-top: 40px;
    color: rgba(0, 0, 0, 0.34);
    max-width: 640px;
    height: 150px; }
  .image-slider .image-size {
    width: 100%;
    height: 480px; }
  .image-slider .image-slider-container {
    position: relative;
    max-width: 960px;
    width: 100%; }
    .image-slider .image-slider-container .images-wrapper {
      position: relative;
      top: 0;
      left: 0; }
      .image-slider .image-slider-container .images-wrapper .slider-image {
        position: absolute; }
    .image-slider .image-slider-container .image-selector-big {
      position: absolute;
      bottom: 0;
      left: 0; }
      .image-slider .image-slider-container .image-selector-big div {
        background: white;
        width: 95px;
        height: 95px; }
        .image-slider .image-slider-container .image-selector-big div img {
          margin: 20px 15px; }
    .image-slider .image-slider-container .image-selector-small {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0; }
      .image-slider .image-slider-container .image-selector-small .left-arrow {
        position: absolute;
        left: 0;
        top: 55px; }
      .image-slider .image-slider-container .image-selector-small .right-arrow {
        position: absolute;
        right: 0;
        top: 55px; }

.lyp-tips-compatibility {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  padding: 20px 48px 20px 10px;
  box-sizing: border-box;
  z-index: 100; }
  .lyp-tips-compatibility a {
    color: #0a88ff; }
  .lyp-tips-compatibility h1 {
    line-height: 1.5;
    font-size: 16px;
    color: #fdfdfd;
    font-weight: 400; }
  .lyp-tips-compatibility .md-icon-button {
    right: 0;
    top: 50%;
    transform: translateY(-50%); }
    .lyp-tips-compatibility .md-icon-button md-icon svg {
      fill: #fdfdfd; }
  @media (min-width: 600px) and (max-width: 959px) {
    .lyp-tips-compatibility {
      padding: 10px 48px 10px 10px; }
      .lyp-tips-compatibility h1 {
        text-align: left; } }
  @media (max-width: 599px) {
    .lyp-tips-compatibility {
      padding: 10px 48px 10px 10px; }
      .lyp-tips-compatibility h1 {
        font-size: 14px;
        text-align: left; } }

.section-indicator {
  width: 307px;
  height: 161px;
  background-image: url("../images/section-header-bg.png");
  position: relative; }
  .section-indicator .number {
    z-index: 3;
    position: absolute;
    margin: 0;
    top: -62px;
    left: 55px;
    background-image: linear-gradient(338deg, #67eefb, #1c8ef7);
    font-size: 120px;
    letter-spacing: 6.7px;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; }
  .section-indicator .title {
    z-index: 2;
    font-size: 32px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 8px;
    color: #fdfdfd;
    margin: 0;
    position: absolute;
    bottom: 34px;
    left: 60px; }

.round-speaker-profile {
  box-sizing: content-box; }
  .round-speaker-profile .avatar-icon-wrapper {
    height: 120px;
    width: 120px;
    position: relative; }
    .round-speaker-profile .avatar-icon-wrapper .blue,
    .round-speaker-profile .avatar-icon-wrapper .red,
    .round-speaker-profile .avatar-icon-wrapper img {
      bottom: 0;
      position: absolute;
      width: 120px;
      height: 120px;
      border-radius: 100px; }
    .round-speaker-profile .avatar-icon-wrapper .blue {
      width: 116px;
      height: 120px;
      border-radius: 100px;
      background-image: linear-gradient(340deg, #67eefb, #1c8ef7);
      left: 6px;
      bottom: 6px; }
    .round-speaker-profile .avatar-icon-wrapper .red {
      width: 116px;
      height: 120px;
      border-radius: 100px;
      background-image: linear-gradient(264deg, #ffd687, #ff6f6f);
      left: -2px;
      bottom: 4px; }
  .round-speaker-profile .name {
    margin: 8px 0;
    font-size: 16px;
    line-height: 1.25;
    letter-spacing: 0.9px;
    font-weight: 400; }
  .round-speaker-profile .description {
    margin: 0;
    font-size: 12px;
    font-weight: 300;
    text-align: center; }

.square-team-partner {
  box-sizing: content-box; }
  .square-team-partner > h5 {
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    margin: 24px 0 8px 0; }
  .square-team-partner > p {
    margin: 0;
    text-align: center;
    font-size: 12px;
    font-weight: 300; }
  .square-team-partner .partner-logo-wrapper {
    position: relative;
    width: 80px;
    height: 80px; }
    .square-team-partner .partner-logo-wrapper .blue,
    .square-team-partner .partner-logo-wrapper .red,
    .square-team-partner .partner-logo-wrapper > div {
      bottom: 0;
      position: absolute;
      width: 80px;
      height: 80px;
      border-radius: 8px; }
    .square-team-partner .partner-logo-wrapper .blue {
      z-index: 2;
      transform: rotateZ(4deg);
      background-image: linear-gradient(340deg, #67eefb, #1c8ef7); }
    .square-team-partner .partner-logo-wrapper .red {
      z-index: 1;
      transform: rotateZ(8deg);
      background-image: linear-gradient(264deg, #ffd687, #ff6f6f); }
    .square-team-partner .partner-logo-wrapper > div {
      z-index: 3;
      background: white no-repeat center;
      background-size: contain; }

.speaker-title {
  position: relative;
  height: 120px; }
  @media (max-width: 599px) {
    .speaker-title {
      height: 200px; } }
  .speaker-title > div {
    position: absolute;
    right: 0;
    height: 120px;
    box-sizing: content-box; }
  .speaker-title .longest-bar,
  .speaker-title .second-bar {
    display: flex;
    align-items: center;
    padding-left: 72px;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px; }
    @media (max-width: 599px) {
      .speaker-title .longest-bar,
      .speaker-title .second-bar {
        box-sizing: border-box;
        height: 80px;
        top: 120px;
        padding-left: 50px; } }
    .speaker-title .longest-bar > h3,
    .speaker-title .second-bar > h3 {
      cursor: pointer;
      margin: 0 0 -4px 0;
      font-size: 24px;
      letter-spacing: 1.3px;
      border-bottom: 4px solid transparent; }
      .speaker-title .longest-bar > h3.current,
      .speaker-title .second-bar > h3.current {
        border-bottom-color: #fdfdfd; }
  .speaker-title .longest-bar {
    width: 65%;
    max-width: 1200px;
    background-image: linear-gradient(25deg, #67eefb, #1c8ef7); }
    @media (min-width: 600px) and (max-width: 959px) {
      .speaker-title .longest-bar {
        max-width: calc(100% - 40px);
        min-width: 640px; } }
    @media (max-width: 599px) {
      .speaker-title .longest-bar {
        border-radius: 0;
        width: 100%; } }
  .speaker-title .second-bar {
    z-index: 1;
    width: 40%;
    max-width: 800px;
    background-image: linear-gradient(25deg, #67eefb, #1c8ef7); }
    @media (min-width: 600px) and (max-width: 959px) {
      .speaker-title .second-bar {
        padding-left: 50px;
        max-width: calc(100% - 160px);
        min-width: 450px; } }
    @media (max-width: 599px) {
      .speaker-title .second-bar {
        width: 50%; } }
  .speaker-title .section-title {
    z-index: 2;
    top: -40px; }

.second-page-header {
  background: url("../images/second-page-header.png") no-repeat bottom center;
  height: 320px;
  background-size: cover;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }
  .second-page-header h1 {
    position: relative;
    color: #fdfdfd;
    font-size: 48px;
    font-weight: 500;
    letter-spacing: 12px; }
    .second-page-header h1:before {
      position: absolute;
      width: 8px;
      height: 8px;
      background-image: linear-gradient(314deg, #ffd687, #ff6f6f);
      border-radius: 100px;
      content: '';
      left: -32px;
      top: 50%; }
    .second-page-header h1:after {
      position: absolute;
      width: 8px;
      height: 8px;
      background-image: linear-gradient(314deg, #ffd687, #ff6f6f);
      border-radius: 100px;
      content: '';
      right: -32px;
      top: 50%; }

.second-page-ticket-and-sponsor {
  width: 100%; }
  @media (max-width: 700px) {
    .second-page-ticket-and-sponsor {
      flex-wrap: wrap; } }
  .second-page-ticket-and-sponsor .left-part-button {
    cursor: pointer; }
    @media (max-width: 700px) {
      .second-page-ticket-and-sponsor .left-part-button {
        min-width: 100%; } }
  .second-page-ticket-and-sponsor .more-partner {
    width: 360px;
    height: 64px;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    background-image: linear-gradient(36deg, #67eefb, #1c8ef7);
    cursor: pointer; }
    @media (max-width: 599px) {
      .second-page-ticket-and-sponsor .more-partner {
        width: 300px; } }
    .second-page-ticket-and-sponsor .more-partner h3 {
      margin: 0;
      font-size: 24px;
      font-weight: 600; }
    @media (max-width: 700px) {
      .second-page-ticket-and-sponsor .more-partner {
        margin-bottom: 50px; } }
  .second-page-ticket-and-sponsor .sponsor-us {
    margin: 0 5% 0 20px;
    font-size: 20px;
    max-width: 700px; }
    .second-page-ticket-and-sponsor .sponsor-us span {
      letter-spacing: 1.1px;
      line-height: 30px; }
    @media (max-width: 700px) {
      .second-page-ticket-and-sponsor .sponsor-us {
        text-align: center;
        margin-right: 20px; } }
