.dialog-custom {
  md-dialog-content {
    padding: 20px;
  }
}

.apply-dialog {
  max-width: 400px;
  box-sizing: border-box;
  @include md-rd-xs {
    max-width: 320px;
  }
  md-dialog-content {
    padding: 80px 40px 30px;
  }
  md-dialog-actions {
    padding-bottom: 12px;
  }
  p {
    font-size: 16px;
  }
}

// 显示大图
.show-big-image {
  @extend .full-size;
  @include max-size(100%, 100%);
  @include min-size(48px, 48px);
  background-color: rgba(0, 0, 0, 0.54);
  box-shadow: none;
  border-radius: 0;
  img {
    @extend .cursor-pointer;
    @include max-size(80vw, 80vh);
    z-index: 10;
  }
  .close {
    @extend .no-margin;
    z-index: 10;
    top: 0;
    right: 0;
    @include button-size(48px, 48px);
    md-icon {
      @include icon-size(32px);
    }
  }
  .arrow {
    @extend .no-margin;
    width: 72px;
    height: 72px;
    @include button-size(72px, 72px);
    &.left,
    &.right {
      transform: translateY(-50%);
      top: 50%;
      z-index: 10;
    }
    &.left {
      left: 20px;
    }
    &.right {
      right: 20px;
    }
    md-icon {
      @include icon-size(48px);
    }
  }
  .page-index {
    text-align: center;
    bottom: 10px;
  }
  .index {
    margin-top: 24px;
    text-align: center;
    @include md-rd-xs {
      margin-top: 0;
    }
  }
  md-progress-circular {
    z-index: 0;
  }
  .page-index {
    position: fixed;
    text-align: center;
    bottom: 10px;
  }
}